@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Roboto:wght@300&display=swap');

html *{
  font-family: 'Inter', sans-serif !important;
  font-family: 'Roboto', sans-serif !important;
  user-select: none;
}

ol > li::marker {
  font-weight: bold;
  font-size: 18px;
}

.pPolicy > .row > * {
  padding: revert !important;
}

.mt20{
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.cPointer{
  cursor: pointer;
}

.blueButton {
  background-color: #09253D;
  color: white;
  font-size: 20px;
  padding: 10px;
}

.nav-link{
  color: white !important;
  cursor: pointer;
  font-size: medium;
}

.aboutBG{
  content: "";
  background-image: url(./images/Faded_Logo.jpg);
  /* background-repeat: no-repeat; */
  background-position: bottom-left;
  /* background-size: contain; */
}

.descPara {
  padding-top: 15px;
  font-size: 16px;
  color: #09253D;
  line-height: 28px;
  padding-bottom: 25px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 580;
}

.descParaContact {
  padding-top: 15px;
  font-size: 16px;
  color: #09253D;
  line-height: 22px;
  padding-bottom: 25px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 580;
}

.descParaJapanese {
  padding-top: 15px;
  font-size: 16px;
  color: #09253D;
  line-height: 28px;
  padding-bottom: 25px;
  text-align: justify;
  text-justify: inter-word;
}

.descPara2 {
  font-size: 16px;
  color: #09253D;
  line-height: 28px;
  padding-bottom: 10px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 580;
  padding-left: 10px;
}

.border-right-blue{
  border-right: 3px solid #09253D !important;
}

.border-right-white{
  border-right: 3px solid white !important;
}

.ptb70{
  padding-top: 70px;
  padding-bottom: 70px;
}

.height-adjust{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading{
  color: #09253D;
  font-size: 50px;
}

.navUpdate{
  font-size: 20px !important;
  color: #09253D !important;
  font-weight: bold !important;
}

.blue{
  background: #09253D;
}

.panel{
  scroll-snap-align: start;
}

.ptb45{
  padding-top: 45px;
  padding-bottom: 45px;
}

.textEd{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center !important;
  color: #09253D;
  padding: 0 !important;
}

.fs50{
  font-size: 50px;
}

.fs50p{
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 0px;
}

.pb1 {
  padding-bottom: 0.25rem !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb50 {
  margin-bottom: 50px !important;
}


.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0px !important;
}

.cBlue {
  color: #09253D;
}

.fs15 {
  font-size: 15px;
  font-weight: 700;
}

.fs20 {
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 580;
}

.pb25{
  padding-bottom: 15px;
}

.blue-gradient {
  background: linear-gradient(180.3deg, #7B848B 5.5%,  #09253D 100%);
}

.grey-gradient {
  background: linear-gradient(-180deg, #B8B8B8 0%,  #7B848B 90.2%);
}

.textWhite {
  color: white
}

.mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px;
}

.p20{
  padding: 20px;
}

.bgWhite {
  background-color: white;
}

.taCenter {
  text-align: center;
}

.cWhite {
  color: white;
}

.fs50 {
  font-size: 50px;
}

.mtl30 {
  margin-left: 50px;
}

.bgBlueBlur {
  background: rgba(255, 255, 255, .9);
}

.imgF {
  filter: grayscale(400%) saturate(500%);
}

.content {
  display: inline-block;
  position: relative;
}

.content a {
  position: absolute;
  bottom: 20px;
  left: 30px;
  padding: 10px;
  background-color: #09253D;
  color: white;
  font-size: medium;
}

.content a:hover {
  cursor: pointer;
}

.mb30 {
  margin-bottom: 30px;
}

.mlb20 {
  margin-bottom: 10px;
  margin-left: 20px;
}

.blueG {
  background-color: #09253D;
  color: white;
  padding: 5px;
  text-align: center;
}

.greyColor {
  color: #7B848B;
}

.fs28 {
  font-size: 2rem !important;
}

.fs17 {
  font-size: 1.71rem !important;
}

.headshot-image {
  width: 100%;
  border-radius: 0%; /* Make the image circular */
  margin-top: 40px;
}

@media (max-width: 991.98px) { 
  .border-right-blue{
    border-right: none !important;
  }
  
  .border-right-white{
    border-right: none !important;
  }

  .fs28{
    font-size: 26px !important;
  }

  .heading{
    color: #09253D;
    font-size: 32px;
  }

  .fs50{
    font-size: 32px;
  }

  .fs15{
    font-size: 13px;
    font-weight: 700;
  }

  .textWhite{
    color: white;
  }

  h2{
    font-size: 20px !important;
  }

  .m5 {
    margin: 7px;
  }

  .pPolicy > .row > * {
    padding: 25px !important;
  }

  .descParaJapanese {
    padding-top: 15px;
    font-size: 14px;
    color: #09253D;
    line-height: 25px;
    padding-bottom: 20px;
    text-align: justify;
    text-justify: inter-word;
  }
  
  .descPara2 {
    font-size: 14px;
    color: #09253D;
    line-height: 25px;
    padding-bottom: 10px;
    text-align: justify;
    text-justify: inter-word;
    font-weight: 580;
    padding-left: 10px;
  }

  ol > li::marker {
    font-weight: bold;
    font-size: 16px;
  }

  .ptb70{
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ptb45{
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pb1{
    padding-bottom: 1rem !important;
  }
}

